import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/glossar"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">&nbsp;</div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1><div class="field field-name-field-title-text field-type-text field-label-hidden"><div class="field-items"><div class="field-item even">Glossar</div></div></div></h1>
                    <div className="view view-glossar view-id-glossar view-display-id-page view-dom-id-ef020f2a3836e333fc819692ce119bb9">
                      <div className="view-content">
                        <div className="views-row views-row-1 views-row-odd views-row-first">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Akuter Durchfall
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Akuter Durchfall ist eine plötzliche
                                    Durchfallattacke, die weniger als zwei
                                    Wochen anhält.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-2 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Abführmittel
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Stoffe oder Arzneimittel, die den Stuhl
                                    aufweichen, den Darminhalt mit Flüssigkeit
                                    anreichern und die Darmbewegungen
                                    verstärken, unter anderem um Verstopfung zu beseitigen,
                                    können bei übermässigem&nbsp;Gebrauch zu
                                    Durchfall führen.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-3 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Allergie</div>
                                <div className="glossar_body">
                                  <p>
                                    Eine Reaktion des Immunsystems auf
                                    Fremdsubstanzen. Substanzen, die Allergien
                                    auslösen, werden Allergene genannt, z. B.
                                    Pollen, Hausstaubmilben und bestimmte
                                    Nahrungsmittel.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-4 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Antibiotika</div>
                                <div className="glossar_body">
                                  <p>
                                    Arzneimittel zur Behandlung von Infektionen, 
                                    die durch Bakterien verursacht werden.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-5 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Aufgeblähter Bauch
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Der Bauch fühlt sich häufig voll und hart
                                    an, wenn Darmgase ihn aufblähen.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-6 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Bakterien</div>
                                <div className="glossar_body">
                                  <p>
                                    Einzellige Mikroorganismen, die häufig mit
                                    Infektionen in Verbindung gebracht werden;
                                    einige von ihnen sind jedoch harmlos
                                    und/oder nützlich für den Menschen.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-7 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Bauch-</div>
                                <div className="glossar_body">
                                  <p>
                                    Auf den Bauch bezogen. Der Bauch ist der
                                    Teil des Körpers, der zwischen der Brust und
                                    dem Becken liegt. Zum Bauch gehören Organe
                                    wie der Magen, der Dünndarm, der Dickdarm,
                                    der Enddarm und die Blase.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-8 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Blähungen</div>
                                <div className="glossar_body">
                                  <p>
                                    Übermässige Gasentwicklung im Darm. Ein etwa
                                    14-maliger Abgang von Gas in 24 Stunden gilt
                                    nochals 'normal'.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-9 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Chronischer Durchfall
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Als chronischer Durchfall werden Durchfall-Symptome
                                    bezeichnet, die länger als vier Wochen
                                    andauern.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-11 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Darm</div>
                                <div className="glossar_body">
                                  <p>
                                    Ein Teil des Verdauungssystems, der im
                                    Bauchraum liegt und aus dem Zwölffingerdarm,
                                    den Dünndarm und dem Dickdarm besteht.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-12 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Dehydrierung
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Wenn der Körper zu viel Wasser verliert.
                                    Erbrechen oder Durchfall über längere Zeit
                                    kann zu Dehydrierung führen, ebenso wie
                                    Hitzeeinwirkung, intensive sportliche
                                    Betätigung über längere Zeit und manche
                                    Medikamente.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-13 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Durchfall</div>
                                <div className="glossar_body">
                                  <p>
                                    Ungewöhnlich lockere oder flüssige Stühle in
                                    Schüben, die mehr als dreimal am Tag
                                    auftreten und/oder Stühle mit einem Volumen
                                    von mehr als 200 g an einem Tag.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-14 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Gastroenteritis
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Eine Entzündung des Magens und des Darms,
                                    die Übelkeit, Erbrechen und/oder Durchfall
                                    hervorrufen kann. Viren,
                                    Lebensmittelvergiftung und Stress können
                                    Gastroenteritis hervorrufen.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-15 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Immunsystem</div>
                                <div className="glossar_body">
                                  <p>
                                    Das natürliche Abwehrsystem unseres Körpers.
                                    Es erkennt Fremdsubstanzen und schützt uns
                                    gegen Infektionen.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-16 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Krampflösendes Mittel / Spasmolytikum
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Ein Medikament, das Muskelkrämpfe minimiert
                                    oder verhindert.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-17 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Loperamid</div>
                                <div className="glossar_body">
                                  <p>
                                    Ein Wirkstoff, der zur Behandlung von
                                    Durchfall verwendet wird, der die
                                    Muskelkontraktionen im Darm verlangsamt und
                                    und dadurch die Aufnahme von Flüssigkeit und
                                    Elektrolyten in den Körper verbessert.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-18 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Menstruation
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Die regelmässige&nbsp;Blutung aus der
                                    Gebärmutter, die in Abständen von ca. 4
                                    Wochen auftritt und den Menstruationszyklus
                                    bestimmt.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-20 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Nährstoffe</div>
                                <div className="glossar_body">
                                  <p>
                                    Substanzen in Nahrungsmitteln, die der
                                    Körper zur Erzeugung von Energie, für
                                    Wachstum und Regeneration braucht wie
                                    Vitamine, Mineralien, Kohlehydrate, Fette
                                    und Proteine.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-22 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Peristaltik</div>
                                <div className="glossar_body">
                                  <p>
                                    Die Bewegung der Muskeln z.B. in unserem Darm,
                                    die sich in rhythmischen Wellen anspannen
                                    und entspannen und so den Darminhalt
                                    durchmischen und vorwärts schieben. Durch
                                    diese Bewegung werden im Magen auch
                                    Nahrungsmittel und Säuren gemischt und in
                                    eine dünne Flüssigkeit verwandelt.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-23 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Rehydrierung
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Der Prozess der Ersetzung von Wasser, das
                                    der Körper verloren hat, durch Trinken von
                                    Flüssigkeiten oder intravenöse Infusion.
                                    Eine Rehydrierung sollte mit der Zufuhr von
                                    Elektrolyten und Mineralien verbunden sein.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="views-row views-row-25 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Schleim</div>
                                <div className="glossar_body">
                                  <p>
                                    Das zähflüssige Produkt der Schleimhäute,
                                    mit denen bestimmte Organe wie z.B. die Nase, der
                                    Mund, der Hals und die Vagina ausgekleidet
                                    sind.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-26 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Sigmoidoskopie
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Eine Untersuchung des Enddarms und des
                                    unteren Dickdarms mittels eines dünnen
                                    beleuchteten Schlauchs namens Sigmoidoskop,
                                    bei der Gewebe- oder Zellproben für die
                                    weitere Untersuchung entnommen werden
                                    können.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-27 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Simeticon</div>
                                <div className="glossar_body">
                                  <p>
                                    Ein Inhaltsstoff, der zur Auflösung von
                                    blähenden Gasblasen beiträgt. Die Gase
                                    werden frei und können ausgeschieden werden.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-28 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Stuhl</div>
                                <div className="glossar_body">
                                  <p>
                                    Auch als 'Darminhalt'&nbsp;
                                    <span
                                      style={{
                                        "font-size": "13.008px",
                                        "line-height": "1.538em"
                                      }}
                                    >
                                      bezeichnete feste Abfallprodukte, die
                                      aufgrund der Darmbewegungen den Enddarm
                                      passieren werden.&nbsp;
                                    </span>
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-29 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Symptom</div>
                                <div className="glossar_body">
                                  <p>
                                    Ein physisches oder mentales Anzeichen, dass
                                    eine Person ein Leiden oder eine Krankheit
                                    hat. Gängige Symptome sind Durchfall,
                                    Kopfschmerzen, Fieber, Müdigkeit, Übelkeit,
                                    Schmerzen.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-30 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Übelkeit</div>
                                <div className="glossar_body">
                                  <p>
                                    Brechreiz, der vielfältige Ursachen haben
                                    kann wie Krankheit, Medikamente und
                                    Störungen im Innenohr.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-31 views-row-odd">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Verstopfung</div>
                                <div className="glossar_body">
                                  <p>
                                    Wenige und häufig unvollständige
                                    Darmentleerungen; das Gegenteil von
                                    Durchfall.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-32 views-row-even">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">Virus</div>
                                <div className="glossar_body">
                                  <p>
                                    Ein Virus ist ein winziger Zellparasit.
                                    Viren sind nicht eigenständig lebensfähig.
                                    Sie dringen in Zellen ein, übernehmen die
                                    Kontrolle über deren Reproduktionsmechanismen
                                    und greifen später auf andere Zellen über.
                                    Sie können 10.000 Mal kleiner sein als
                                    Bakterien.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="views-row views-row-33 views-row-odd views-row-last">
                          <div className="views-field views-field-body">
                            {" "}
                            <div className="field-content">
                              <div className="glossar_item">
                                <div className="glossar_title">
                                  Zentrales Nervensystem
                                </div>
                                <div className="glossar_body">
                                  <p>
                                    Das zentrale Nervensystem (ZNS) ist der Teil
                                    des Nervensystems, das aus dem Gehirn und
                                    dem Rückenmark besteht.
                                  </p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
